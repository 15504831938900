import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

function ModalCek({ date }) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = async () => {
    setShow(true);
    setLoading(true);
    try {
      const response = await axios.post(`${apiUrl}/khodam`, { date });
      setLoading(false);
      setResult(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Button variant="primary" disabled={!date} onClick={handleShow}>
        Cek Sekarang
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Khodam Weton</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="text-center">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <p className="m-0">Tanggal Lahir</p>
                  <h6 className="mb-1">{result.birthday}</h6>
                </li>
                <li className="list-group-item">
                  <p className="m-0">Weton</p>
                  <h6 className="mb-1">{result.weton}</h6>
                </li>
                <li className="list-group-item">
                  <p className="m-0">Neptu</p>
                  <h6 className="mb-1">{result.neptu}</h6>
                </li>
                <li className="list-group-item">
                  <p className="m-0">Khodam</p>
                  <h6 className="mb-1">{result.khodam}</h6>
                </li>
                <li className="list-group-item">
                  <p className="m-0">Pengaruh</p>
                  <h6 className="mb-1">{result.pengaruh}</h6>
                </li>
                <li className="list-group-item">
                  <p className="m-0">Referensi</p>
                  <h6 className="mb-1">{result.referensi}</h6>
                </li>
              </ul>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Oke
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalCek;
