import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalCek from "./components/ModalCek";

const Home = () => {
  const [date, setDate] = useState("");

  const cek = (e) => {
    e.preventDefault();

    console.log(date);
  };

  return (
    <section className="d-flex flex-column min-vh-100">
      <div className="container py-5">
        <h2 className="text-center">Cek Khodam berdasarkan Weton</h2>
        <hr />
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <label htmlFor="date" className="form-label">
              Tanggal Lahir
            </label>
            <form onSubmit={cek}>
              <div className="input-group">
                <input
                  type="date"
                  className="form-control"
                  id="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
                <ModalCek date={date} />
              </div>
            </form>
            <div id="date" className="form-text">
              Tenang, tanggal lahirmu tidak akan disimpan.
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
        <h6 className="mt-5">Referensi:</h6>
        <ul>
          <li>
            <Link
              to="https://intisari.grid.id/read/033876951/cara-mengetahui-weton-dari-tanggal-lahir-dan-tahun-secara-online?page=all"
              target="_blank"
            >
              Cara Menghitung Weton dari Tanggal Lahir
            </Link>
          </li>
          <li>
            <Link
              to="https://www.suaramerdeka.com/gaya-hidup/0412913569/lengkap-daftar-khodam-penjaga-35-weton-menurut-kitab-primbon-jawa"
              target="_blank"
            >
              Khodam Penjaga 35 Weton Menurut Kitab Primbon Jawa
            </Link>
          </li>
        </ul>
      </div>
      <footer className="text-center mt-auto py-3">
        &copy; 2024 <Link to="/">Khodam Weton</Link> by{" "}
        <Link to="https://fergus.my.id" target="_blank">
          Ferdi Gusnanto
        </Link>
      </footer>
    </section>
  );
};

export default Home;
